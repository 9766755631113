import { StorageAccessor } from "./storageAccessor";
var StorageFactory = (function () {
    function StorageFactory() {
        this.innerWindow = window;
    }
    StorageFactory.prototype.window = function (window) {
        this.innerWindow = window;
        return this;
    };
    StorageFactory.prototype.storage = function (storage) {
        return new StorageAccessor(storage);
    };
    StorageFactory.prototype.localStorage = function () {
        return this.storage(this.innerWindow.localStorage);
    };
    StorageFactory.prototype.sessionStorage = function () {
        return this.storage(this.innerWindow.sessionStorage);
    };
    return StorageFactory;
}());
export { StorageFactory };
