var StorageAccessor = (function () {
    function StorageAccessor(storage) {
        this.storage = storage;
    }
    StorageAccessor.prototype.key = function (n) {
        return this.storage.key(n);
    };
    StorageAccessor.prototype.set = function (key, value) {
        try {
            this.storage.setItem(key, value);
        }
        catch (e) {
        }
    };
    StorageAccessor.prototype.get = function (key) {
        return this.storage.getItem(key);
    };
    StorageAccessor.prototype.setObject = function (key, obj, ttl) {
        ttl = ttl || 0;
        var created = new Date().getTime();
        var value = JSON.stringify({ obj: obj, ttl: ttl, created: created });
        try {
            this.storage.setItem(key, value);
        }
        catch (e) {
            return false;
        }
        return true;
    };
    StorageAccessor.prototype.getObject = function (key) {
        try {
            var value = JSON.parse(this.storage.getItem(key) || "null");
            if (!value) {
                return null;
            }
            if (value.ttl && value.ttl + value.created < new Date().getTime()) {
                this.storage.removeItem(key);
                return null;
            }
            return value.obj;
        }
        catch (e) {
            return undefined;
        }
    };
    StorageAccessor.prototype.remove = function (key) {
        try {
            this.storage.removeItem(key);
        }
        catch (e) {
            return false;
        }
        return true;
    };
    StorageAccessor.prototype.clear = function () {
        this.storage.clear();
    };
    return StorageAccessor;
}());
export { StorageAccessor };
