var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EMPTY as observableEmpty, throwError } from "rxjs";
import { ajax, AjaxError } from "rxjs/ajax";
import { catchError } from "rxjs/operators";
import $ from "../common/jquery";
export var HTML_RESPONSE_TYPE = "text";
export var JSON_RESPONSE_TYPE = "json";
export var X_REQUESTED_WITH_VALUE = "XMLHttpRequest";
export var CSRF_HEADER_NAME = "X-XSRF-TOKEN";
export var CSRF_COOKIE_NAME = "XSRF-TOKEN";
var APPLICATION_JSON_UTF8_CONTENT_TYPE = "application/json;charset=UTF-8";
var csrfTokenFromDOM = function () {
    return $("meta[name='_csrf']").attr("content");
};
var csrfTokenFromCookie = function () { return document.cookie.split(/;\s*/).filter(function (nameValue) {
    var name = nameValue.split("=")[0];
    return name === CSRF_COOKIE_NAME;
}).map(function (nameValue) {
    return nameValue.slice(CSRF_COOKIE_NAME.length + 1);
}).shift(); };
var csrfToken = function () { return csrfTokenFromCookie() || csrfTokenFromDOM(); };
var AjaxService = (function () {
    function AjaxService() {
    }
    AjaxService.getInstance = function () {
        return AjaxService.instance;
    };
    AjaxService.createPostRequest = function (url, body, responseType, headers, timeout) {
        if (timeout === void 0) { timeout = 0; }
        var _a;
        headers = __assign((_a = { "X-Requested-With": X_REQUESTED_WITH_VALUE }, _a[CSRF_HEADER_NAME] = csrfToken(), _a), headers);
        var request = { url: url, headers: headers, body: body, responseType: responseType, timeout: timeout, method: "POST" };
        if (!responseType) {
            delete request.responseType;
        }
        return request;
    };
    AjaxService.prototype.doGet = function (url) {
        return ajax({
            headers: { "X-Requested-With": X_REQUESTED_WITH_VALUE },
            responseType: HTML_RESPONSE_TYPE,
            url: url
        });
    };
    AjaxService.prototype.doGetSafe = function (url) {
        return this.doGet(url).pipe(catchError(function () { return observableEmpty; }));
    };
    AjaxService.prototype.doGetSafeWithCredentials = function (url) {
        return ajax({
            headers: { "X-Requested-With": X_REQUESTED_WITH_VALUE },
            responseType: HTML_RESPONSE_TYPE,
            url: url,
            withCredentials: true
        }).pipe(catchError(function () { return observableEmpty; }));
    };
    AjaxService.prototype.doPostForCrossDomain = function (url, body) {
        var header = {
            "Content-Type": "application/json"
        };
        var request = { url: url, header: header, body: body, crossDomain: true, method: "POST" };
        return this.getAjaxObservable(request);
    };
    AjaxService.prototype.doPost = function (url, body, responseType, customHeader) {
        var request = AjaxService.createPostRequest(url, body, responseType, customHeader);
        return this.getAjaxObservable(request);
    };
    AjaxService.prototype.doPostSafe = function (url, body, responseType, customHeader) {
        return this.doPost(url, body, responseType, customHeader).pipe(catchError(function () { return observableEmpty; }));
    };
    AjaxService.prototype.postJSON = function (url, body, responseType, customHeader) {
        customHeader = __assign({}, customHeader, { "Content-Type": "application/json" });
        var request = AjaxService.createPostRequest(url, body, responseType, customHeader);
        return this.getAjaxObservable(request);
    };
    AjaxService.prototype.postJSONSafe = function (url, body, responseType, customHeader) {
        return this.postJSON(url, body, responseType, customHeader).pipe(catchError(function () { return observableEmpty; }));
    };
    AjaxService.prototype.doPostWithTimeout = function (url, timeout, body, responseType, customHeader) {
        var request = AjaxService.createPostRequest(url, body, responseType, customHeader, timeout);
        return this.getAjaxObservable(request);
    };
    AjaxService.prototype.getAjaxObservable = function (ajaxRequest) {
        var _this = this;
        return ajax(ajaxRequest).pipe(catchError(function (err) { return _this.reportNonAjaxError(err, ajaxRequest.url); }));
    };
    AjaxService.prototype.reportNonAjaxError = function (error, url) {
        if (!(error instanceof AjaxError)) {
            var pageName = document.getElementsByName("_page_name")[0].getAttribute("content");
            var vmName = document.getElementsByName("_vm_name")[0].getAttribute("content");
            var slice = document.getElementsByName("_slice")[0].getAttribute("content");
            var ajaxRequest = AjaxService.createPostRequest("/orion/errors", {
                columnNumber: null,
                error: error.stack,
                eventOrMessage: "Error " + error.name + " thrown from Observable requesting " + url + ": " + error.message,
                file: "",
                lineNo: null,
                pageName: pageName,
                slice: slice,
                userAgent: navigator.userAgent,
                vmName: vmName,
            }, HTML_RESPONSE_TYPE, { "Content-Type": APPLICATION_JSON_UTF8_CONTENT_TYPE });
            ajax(ajaxRequest).subscribe();
        }
        return throwError(error);
    };
    AjaxService.instance = new AjaxService();
    return AjaxService;
}());
export { AjaxService };
