export var getMetaContent = function (metaName) {
    var metas = document.getElementsByTagName("meta");
    var re = new RegExp("\\b" + metaName + "\\b", "i");
    var mLength = metas.length;
    for (var i = 0; i < mLength; i++) {
        if (re.test(metas[i].getAttribute("name"))) {
            return metas[i].getAttribute("content");
        }
    }
    return "";
};
