var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { from as observableFrom, fromEvent as observableFromEvent, merge, Subject, timer } from "rxjs";
import { bufferToggle, finalize, debounceTime, map, tap, reduce, throttleTime, bufferWhen, takeUntil } from "rxjs/operators";
import "parsleyjs";
import { AjaxService } from "./common/ajax";
import { ObjectUtils } from "./common/objectUtils";
import { sessionStorage } from "./common/storage";
import { isBlank, isDefinedAndNotNull } from "./common/utils/objectUtil";
import { getMetaContent } from "./common/utils/metaContent";
var DATA_ACTION_CAPTURE = "data-action-capture";
var DATA_ACTION_SERVLET_NAME = "data-action-servlet-name";
var DATA_ACTION_TAG = "data-action-tag";
var DATA_ACTION_PAGE_PROPERTIES = "data-action-page-properties";
var DATA_ACTION_PAGE_PROPERTIES_DYNAMIC = "data-action-page-properties-dynamic";
var DATA_ACTION_ERROR_PAGE_PROPERTIES = "data-action-error-page-properties";
var DATA_ACTION_DESTINATION_ID = "data-action-destination-id";
var DATA_ACTION_PRODUCT_ATTRIBUTE = "data-action-prod-attr";
var DATA_ACTION_LAYOUT = "data-action-layout";
var DATA_ACTION_TRACK_ONLY_SHOWN = "data-action-track-only-shown";
var DATA_ATTRACTION_PRODUCT_ID = "data-attraction-product-id";
var DATA_ATTRACTION_ITINERARY_ID = "data-attraction-itinerary-id";
var DATA_TRACKING_INITIAL_IMPRESSION = "data-tracking-initial-impression";
var DATA_TRACKING_INITIAL_IMPRESSION_TAG = "data-tracking-initial-impression-tag";
var SCROLL_EVENT = "scroll";
var INITIAL_IMPRESSION_TRACKING_PAGE_PROPERTIES = "impression_type:initial_impression";
var PAGES_OK_FOR_INTERSECTION_SCAN = ["product_detail"];
var ClickStream = (function () {
    function ClickStream(rootElement, $window) {
        if (rootElement === void 0) { rootElement = ":root"; }
        if ($window === void 0) { $window = $(window); }
        this.darkMediaQuery = null;
        this.postClickStream = function (sn, pageAct, pp, destinationID, itineraryID, prdAttr, productId) {
            return ClickStream.postClickStream(sn, pageAct, pp, destinationID, itineraryID, prdAttr, productId);
        };
        this.rootElement = $(rootElement);
        this.$window = $window;
    }
    ClickStream.getServlet = function () {
        var pageServletName = document.querySelector('meta[name="_page_name"]');
        return pageServletName && pageServletName.getAttribute("content");
    };
    ClickStream.postClickStreamWithCallback = function (trackingArguments, callback) {
        ClickStream.ajaxService
            .postJSONSafe(ClickStream.PAGE_ACTION_URL, ClickStream.wrapPageTrackingDetails(trackingArguments)).pipe(finalize(callback))
            .subscribe(function () { });
    };
    ClickStream.postClickStreamWithTrackingArguments = function (trackingArguments) {
        ClickStream.postClickStreamWithCallback(trackingArguments, function () { return; });
    };
    ClickStream.postClickStream = function (servletName, pageAction, pageProperties, destinationID, itineraryID, productAttribute, attractionProductId) {
        var body = {
            attractionProductId: attractionProductId,
            destinationID: destinationID,
            itineraryID: itineraryID,
            pageAction: pageAction,
            pageProperties: pageProperties,
            productAttribute: productAttribute,
            servletName: servletName
        };
        ClickStream.ajaxService
            .postJSONSafe(ClickStream.PAGE_ACTION_URL, ClickStream.wrapPageTrackingDetails(body))
            .subscribe(function () { });
    };
    ClickStream.trackVisibleElements = function (elements, trackingCallback) {
        if ("IntersectionObserver" in window) {
            this.trackVisibleElementsWithIntersectionObserver(elements, trackingCallback, true);
        }
        else {
            this.trackVisibleElementsWithoutIntersectionObserver(elements, trackingCallback);
        }
    };
    ClickStream.trackVisibleElementsInScrollDiv = function (scrollDiv, elements, trackingCallback) {
        if ("IntersectionObserver" in window) {
            this.trackVisibleElementsWithIntersectionObserver(elements, trackingCallback, false);
        }
        else {
            this.trackVisibleElementsInScrollDivWithoutIntersectionObserver(scrollDiv, elements, trackingCallback);
        }
    };
    ClickStream.getPageViewRequestIdFromDom = function () {
        var pageViewRequestId = document.querySelector('meta[name="_page_view_request_id"]');
        return pageViewRequestId && pageViewRequestId.getAttribute("content");
    };
    ClickStream.updatePageViewRequestId = function () {
        var pageViewRequestId = ClickStream.getPageViewRequestIdFromDom();
        var parentPageViewRequestId = sessionStorage.getObject(ClickStream.PAGE_VIEW_REQUEST_ID);
        if (pageViewRequestId && parentPageViewRequestId !== pageViewRequestId) {
            if (parentPageViewRequestId !== null) {
                sessionStorage.setObject(ClickStream.PARENT_PAGE_VIEW_REQUEST_ID, parentPageViewRequestId);
            }
            sessionStorage.setObject(ClickStream.PAGE_VIEW_REQUEST_ID, pageViewRequestId);
            return true;
        }
        return false;
    };
    ClickStream.wrapPageTrackingDetails = function (trackingArguments) {
        var tracking = trackingArguments;
        var parentPageViewRequestId = sessionStorage.getObject(ClickStream.PARENT_PAGE_VIEW_REQUEST_ID);
        var pageViewRequestId = sessionStorage.getObject(ClickStream.PAGE_VIEW_REQUEST_ID);
        if (pageViewRequestId) {
            tracking.pageViewRequestId = pageViewRequestId;
            if (parentPageViewRequestId) {
                tracking.parentPageViewRequestId = parentPageViewRequestId;
            }
        }
        tracking.pageProperties = __assign({}, (tracking.pageProperties || {}), { timeOfAction: String(Date.now()), timeToAction: String(Math.floor(performance.now())), uiImplementation: 'dust' });
        return tracking;
    };
    ClickStream.isInViewportAndVisible = function (element) {
        if (this.isHidden(element)) {
            return false;
        }
        var rect = element.getBoundingClientRect();
        return (rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth));
    };
    ClickStream.isHidden = function (element) {
        return window.getComputedStyle(element).display === "none"
            || window.getComputedStyle(element).visibility === "hidden";
    };
    ClickStream.trackVisibleElementsWithIntersectionObserver = function (elements, trackingCallback, doDisconnect) {
        var observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (ClickStream.isHidden(entry.target) || !entry.isIntersecting || entry.intersectionRatio !== 1) {
                    return;
                }
                trackingCallback(entry.target);
                observer.unobserve(entry.target);
            });
            if (doDisconnect) {
                observer.disconnect();
            }
        }, { threshold: 1.0 });
        elements.each(function (index, element) {
            observer.observe(element);
        });
        $(window).scroll();
    };
    ClickStream.trackVisibleElementsWithoutIntersectionObserver = function (elements, trackingCallback) {
        elements.each(function (index, element) {
            if (!ClickStream.isInViewportAndVisible(element)) {
                return;
            }
            trackingCallback(element);
        });
    };
    ClickStream.trackVisibleElementsInScrollDivWithoutIntersectionObserver = function (scrollDiv, elements, trackingCallback) {
        observableFromEvent(scrollDiv, "scroll")
            .pipe(throttleTime(100))
            .subscribe(function () {
            ClickStream.trackVisibleElementsWithoutIntersectionObserver(elements, trackingCallback);
        });
        ClickStream.trackVisibleElementsWithoutIntersectionObserver(elements, trackingCallback);
    };
    ClickStream.prototype.bootstrap = function () {
        var htmlElem = $("html").get(0);
        var platform = navigator.platform;
        if (!htmlElem.dataset.ClickStreamIsBootstrapped) {
            this.darkMediaQuery = window.matchMedia &&
                window.matchMedia("(" + ClickStream.PREFERS_COLOR_SCHEME_DARK + ")");
            this.hasMultiTouchEnabled = navigator.maxTouchPoints > 1;
            this.isIOS = /iPad|iPhone|iPod/.test(platform) ||
                (platform === 'MacIntel' && this.hasMultiTouchEnabled);
            ClickStream.updatePageViewRequestId();
            this.addDeviceInformationToClickstream();
            this.addListenerForRefererPageIdUpdate();
        }
        htmlElem.dataset.ClickStreamIsBootstrapped = true;
        if (document.hasFocus()) {
            this.updateRefererPageRequestIdCookieFromDom();
        }
        this.trackInitialImpressions();
        this.scanAndRegisterSubscriber();
        this.scanElementsInError();
    };
    ClickStream.prototype.addDeviceInformationToClickstream = function () {
        var _this = this;
        ClickStream.postClickStream(ClickStream.getServlet(), "screenSizeLoad", this.getDeviceProperties());
        observableFromEvent(this.$window, "resize").pipe(debounceTime(500)).subscribe(function () {
            ClickStream.postClickStream(ClickStream.getServlet(), "screenSizeChange", _this.getDeviceProperties());
        });
        if (this.darkMediaQuery) {
            observableFromEvent(this.darkMediaQuery, "change").subscribe(function () {
                ClickStream.postClickStreamWithTrackingArguments({
                    servletName: ClickStream.getServlet(),
                    pageAction: "colorSchemeChange",
                    pageProperties: _this.getDeviceProperties(),
                });
            });
        }
    };
    ClickStream.prototype.addElementsToClickStream = function (elements) {
        var _this = this;
        observableFrom(elements).pipe(map(function (element) { return _this.convertElementToClickStreamData(element); })).subscribe(function (clickStreamData) {
            _this.createClickStreamObservable(clickStreamData);
        });
        this.addProductShelfElements(elements);
    };
    ClickStream.prototype.createClickStreamObservable = function (clickStreamData) {
        var _this = this;
        var isScrollEvent = clickStreamData.capturedEvent === "scroll";
        var unsubscribe$ = new Subject();
        observableFromEvent(isScrollEvent ? this.$window : clickStreamData.scannedElement, clickStreamData.capturedEvent, isScrollEvent ? { passive: true } : null)
            .pipe(takeUntil(unsubscribe$), throttleTime(isScrollEvent ? 100 : 0))
            .subscribe(function () {
            if (!clickStreamData.trackOnlyShown ||
                $(clickStreamData.scannedElement).filter(":visible")[0]) {
                var pageAction = clickStreamData.pageAction;
                if (clickStreamData.capturedEvent === "change") {
                    pageAction += ":" + clickStreamData.scannedElement.value;
                }
                if (isScrollEvent) {
                    if (!_this.isElementShownVertical(clickStreamData.scannedElement)) {
                        return;
                    }
                    else {
                        unsubscribe$.next();
                        unsubscribe$.complete();
                    }
                }
                clickStreamData.servletName =
                    clickStreamData.servletName || ClickStream.getServlet();
                var pageProperties = (!!clickStreamData.pagePropertiesDynamic)
                    ? clickStreamData.scannedElement.getAttribute(DATA_ACTION_PAGE_PROPERTIES)
                    : clickStreamData.pageProperties;
                ClickStream.postClickStream(clickStreamData.servletName, pageAction, ObjectUtils.stringToObject(pageProperties), clickStreamData.destinationID, clickStreamData.itineraryID, clickStreamData.productAttribute, clickStreamData.attractionProductId);
            }
        });
    };
    ClickStream.prototype.addProductShelfElements = function (elements) {
        var _this = this;
        var clickStreamDataObjects = $.map(elements, function (element) { return _this.convertElementToClickStreamData(element); });
        var productShelfClickStreamDataObjects = clickStreamDataObjects.filter(function (clickStreamData) {
            return clickStreamData.pageAction === "shelf_product_in_view";
        });
        var productShelfObservables = productShelfClickStreamDataObjects.map(function (clickStreamData) {
            return observableFromEvent([_this.$window, $(clickStreamData.scannedElement).parents(".slider").get()], SCROLL_EVENT, { passive: true })
                .pipe(map(function (event) { return clickStreamData; }));
        });
        var mergedProductShelfObservables = merge.apply(void 0, productShelfObservables);
        mergedProductShelfObservables.pipe(bufferWhen(function () { return timer(2000); }))
            .subscribe(function (scannedElements) {
            _this.batchEvents(scannedElements);
        });
    };
    ClickStream.prototype.convertElementToClickStreamData = function (element) {
        var html = $("html").get(0);
        return {
            attractionProductId: element.getAttribute(DATA_ATTRACTION_PRODUCT_ID),
            capturedEvent: element.getAttribute(DATA_ACTION_CAPTURE),
            captureInitialImpression: element.getAttribute(DATA_TRACKING_INITIAL_IMPRESSION),
            initialImpressionTag: element.getAttribute(DATA_TRACKING_INITIAL_IMPRESSION_TAG),
            destinationID: element.getAttribute(DATA_ACTION_DESTINATION_ID)
                || html.getAttribute(DATA_ACTION_DESTINATION_ID),
            itineraryID: element.getAttribute(DATA_ATTRACTION_ITINERARY_ID),
            layout: element.getAttribute(DATA_ACTION_LAYOUT),
            pageAction: element.getAttribute(DATA_ACTION_TAG),
            pageProperties: element.getAttribute(DATA_ACTION_PAGE_PROPERTIES)
                || html.getAttribute(DATA_ACTION_PAGE_PROPERTIES),
            pagePropertiesDynamic: element.getAttribute(DATA_ACTION_PAGE_PROPERTIES_DYNAMIC),
            productAttribute: element.getAttribute(DATA_ACTION_PRODUCT_ATTRIBUTE),
            scannedElement: element,
            servletName: element.getAttribute(DATA_ACTION_SERVLET_NAME) || ClickStream.getServlet(),
            trackOnlyShown: element.getAttribute(DATA_ACTION_TRACK_ONLY_SHOWN)
        };
    };
    ClickStream.prototype.isElementShown = function (element) {
        return this.isElementShownVertical(element) && this.isElementShownHorizontal(element);
    };
    ClickStream.prototype.isElementShownVertical = function (element) {
        if ($(element).outerHeight() === 0) {
            return false;
        }
        var criticalLine = $(element).offset().top + $(element).outerHeight();
        var scrolledDistance = this.$window.height() + this.$window.scrollTop();
        return scrolledDistance >= criticalLine;
    };
    ClickStream.prototype.isElementShownHorizontal = function (element) {
        var criticalLineLeft = $(element).offset().left;
        var criticalLineRight = criticalLineLeft + $(element).width();
        var container = $(element).parents(".slider-container");
        var leftEdge = container.offset().left;
        var rightEdge = leftEdge + container.width();
        return leftEdge <= criticalLineLeft && rightEdge >= criticalLineRight;
    };
    ClickStream.prototype.addListenerForRefererPageIdUpdate = function () {
        var _this = this;
        window.addEventListener("focus", function () { return _this.updateRefererPageRequestIdCookieFromDom(); });
    };
    ClickStream.prototype.updateRefererPageRequestIdCookieFromDom = function () {
        document.cookie = ClickStream.REFERER_PAGE_REQUEST_ID + "=" + ClickStream.getPageViewRequestIdFromDom() +
            ";path=/";
    };
    ClickStream.prototype.batchEvents = function (clickStreamDataObjects) {
        var _this = this;
        var shelfTypeList = [];
        var positionsList = [];
        var productCodesList = [];
        var urgencyList = [];
        clickStreamDataObjects.forEach(function (clickStreamData) {
            var element = clickStreamData.scannedElement;
            if ($(element).filter(":visible")[0]) {
                if (_this.isElementShown(element) && $(element).data("impression-has-fired") !== true) {
                    var pageProperties = new Map(element.getAttribute(DATA_ACTION_PAGE_PROPERTIES)
                        .split("|")
                        .filter(function (pageProperty) { return pageProperty.split(":").length === 2; })
                        .map(function (pageProperty) {
                        return [pageProperty.split(":")[0], pageProperty.split(":")[1]];
                    }));
                    var shelfType = pageProperties.get("shelfGroupId");
                    var position = pageProperties.get("position");
                    var productCode = element.getAttribute(DATA_ACTION_PRODUCT_ATTRIBUTE);
                    var bookingUrgency = pageProperties.get("urgency");
                    shelfTypeList.push(shelfType);
                    positionsList.push(position);
                    productCodesList.push(productCode);
                    if (isDefinedAndNotNull(bookingUrgency)) {
                        urgencyList.push(bookingUrgency);
                    }
                    $(element).data("impression-has-fired", true);
                }
            }
        });
        if (productCodesList.length > 0 && positionsList.length > 0) {
            var shelfTypes = ["[", shelfTypeList.join(), "]"].join("");
            var positions = ["[", positionsList.join(), "]"].join("");
            var productCodes = ["[", productCodesList.join(), "]"].join("");
            var urgencies = ["[", urgencyList.join(), "]"].join("");
            ClickStream.postClickStreamWithTrackingArguments({
                pageAction: "shelf_product_in_view",
                pageProperties: __assign({ shelfTypes: shelfTypes,
                    positions: positions,
                    productCodes: productCodes }, (urgencyList.length > 0 && { urgencies: urgencies })),
                servletName: ClickStream.getServlet()
            });
        }
    };
    ClickStream.prototype.scanElementsInError = function () {
        var elementsInError = this.rootElement.find("[" + DATA_ACTION_ERROR_PAGE_PROPERTIES + "]");
        observableFrom(elementsInError).pipe(map(function (element) {
            return {
                pageAction: element.getAttribute(DATA_ACTION_TAG),
                pageProperties: element.getAttribute(DATA_ACTION_ERROR_PAGE_PROPERTIES),
                servletName: element.getAttribute(DATA_ACTION_SERVLET_NAME)
            };
        }), reduce(function (element1, element2) {
            return element1.pageProperties += element2.pageProperties;
        }))
            .subscribe(function (clickStreamData) {
            ClickStream.postClickStream(clickStreamData.servletName, clickStreamData.pageAction, ObjectUtils.stringToObject(clickStreamData.pageProperties));
        });
    };
    ClickStream.prototype.scanElements = function () {
        return this.rootElement.find("[" + DATA_ACTION_CAPTURE + "]");
    };
    ClickStream.prototype.scanElementsForInitialImpression = function () {
        return this.rootElement.find("[" + DATA_TRACKING_INITIAL_IMPRESSION + "]");
    };
    ClickStream.prototype.canPageUseIntersectionObserver = function () {
        var pageName = getMetaContent("_page_name");
        return PAGES_OK_FOR_INTERSECTION_SCAN.indexOf(pageName) !== -1 && "IntersectionObserver" in window;
    };
    ClickStream.prototype.scanAndRegisterSubscriber = function () {
        var _this = this;
        var elements = this.scanElements();
        if (elements.length > 0) {
            if (this.canPageUseIntersectionObserver()) {
                elements.each(function (index, elem) {
                    var observer = new IntersectionObserver(function (entries, observer) {
                        if (entries[0].isIntersecting === true) {
                            var clickStreamData = _this.convertElementToClickStreamData(elem);
                            _this.createClickStreamObservable(clickStreamData);
                            observer.disconnect();
                        }
                    }, { rootMargin: "100px 0px 0px 0px" });
                    observer.observe(elem);
                });
                this.addProductShelfElements(elements);
            }
            else {
                this.addElementsToClickStream(elements);
            }
        }
        this.trackParsleyErrors();
    };
    ClickStream.prototype.trackParsleyErrors = function () {
        var _this = this;
        if (!Parsley) {
            return;
        }
        var errorFields$ = new Subject();
        observableFromEvent(Parsley, "field:error").pipe(map(function (_a) {
            var fieldInstance = _a[0], extraArgs = _a[1];
            return fieldInstance;
        }), tap(function (fieldInstance) { return errorFields$.next(fieldInstance); }), debounceTime(200))
            .subscribe(function (fieldInstance) {
            _this.trackFieldInError(fieldInstance);
        });
        var formValidate$ = observableFromEvent(Parsley, "form:validate");
        var formValidated$ = observableFromEvent(Parsley, "form:validated");
        errorFields$.pipe(bufferToggle(formValidate$, function () { return formValidated$; }))
            .subscribe(function (fields) {
            var dataParsleyGroup = fields[0] && fields[0].$element.attr("data-parsley-group");
            var validGroup = fields[0] && fields[0].$element.parsley().isValid({ group: dataParsleyGroup });
            if (!dataParsleyGroup || validGroup) {
                return;
            }
            var dataPageAction = $("button[data-parsley-group='" + dataParsleyGroup + "']")
                .attr(DATA_ACTION_TAG) || "impression_form_validation_error";
            var dataServletName = $("button[data-parsley-group='" + dataParsleyGroup + "']")
                .attr(DATA_ACTION_SERVLET_NAME) || ClickStream.getServlet();
            var productAttributes = fields.map(function (field) {
                var parsleyErrorId = field.$element.attr("data-parsley-id");
                var parsleyMultiple = field.$element.attr("data-parsley-multiple");
                var errorMessageNode = _this.rootElement
                    .find("#parsley-id-" + parsleyErrorId + ", #parsley-id-multiple-" + parsleyMultiple);
                if (errorMessageNode.length === 0) {
                    return "";
                }
                for (var _i = 0, _a = errorMessageNode[0].children; _i < _a.length; _i++) {
                    var errorMessage = _a[_i];
                    if (Object.keys(ClickStream.errorMaps).indexOf(errorMessage.getAttribute("class")) >= 0) {
                        return _this.logNameAndError(field, errorMessage);
                    }
                }
                return "";
            }).filter(function (error) { return error.length > 0; });
            if (productAttributes.length > 0) {
                ClickStream.postClickStream(dataServletName, dataPageAction, null, null, null, productAttributes.join("|"));
            }
        });
    };
    ClickStream.prototype.trackFieldInError = function (fieldInError) {
        var parsleyErrorId = fieldInError.$element.attr("data-parsley-id");
        var errorMessageNode = this.rootElement.find("#parsley-id-" + parsleyErrorId);
        var dataPageAction = "impression_form_field_validation_error";
        var dataServletName = errorMessageNode.attr(DATA_ACTION_SERVLET_NAME);
        dataServletName = dataServletName || ClickStream.getServlet();
        var errorNode = errorMessageNode[0];
        if (errorNode && errorNode.children) {
            for (var _i = 0, _a = errorNode.children; _i < _a.length; _i++) {
                var errorMessage = _a[_i];
                if (Object.keys(ClickStream.errorMaps).indexOf(errorMessage.getAttribute("class")) >= 0) {
                    var productAttribute = this.logNameAndError(fieldInError, errorMessage);
                    ClickStream.postClickStream(dataServletName, dataPageAction, null, null, null, productAttribute);
                }
            }
        }
    };
    ClickStream.prototype.logNameAndError = function (fieldInError, errorMessage) {
        if (fieldInError.$element.attr("name") !== undefined) {
            var fieldNameArr = fieldInError.$element.attr("name").split(".");
            if (fieldNameArr.length > 3) {
                return fieldNameArr.splice(-3).join(".") + ":" +
                    ClickStream.errorMaps[errorMessage.getAttribute("class")];
            }
            else {
                return fieldNameArr.join(".") + ":" + ClickStream.errorMaps[errorMessage.getAttribute("class")];
            }
        }
        else if (fieldInError.$element.attr("id") !== undefined) {
            var fieldIdArr = fieldInError.$element.attr("id").split(".");
            if (fieldIdArr.length > 3) {
                return fieldIdArr.splice(-3).join(".") + ":" +
                    ClickStream.errorMaps[errorMessage.getAttribute("class")];
            }
            else {
                return fieldIdArr.join(".") + ":" + ClickStream.errorMaps[errorMessage.getAttribute("class")];
            }
        }
        return "";
    };
    ClickStream.prototype.getDeviceProperties = function () {
        var win = this.$window;
        var innerWidth = win.innerWidth();
        var innerHeight = win.innerHeight();
        var orientation = (innerHeight > innerWidth) ? "portrait" : "landscape";
        var screen = win.get(0).screen;
        return {
            colorScheme: this.darkMediaQuery && this.darkMediaQuery.matches ? "dark" : "light",
            screen: screen.width + "X" + screen.height,
            viewport: innerWidth + "X" + innerHeight,
            orientation: orientation,
            multiTouch: "" + this.hasMultiTouchEnabled,
            iOS: "" + this.isIOS,
        };
    };
    ClickStream.prototype.trackElementInitialImpression = function (element) {
        var clickStreamData = this.convertElementToClickStreamData(element);
        if (clickStreamData.captureInitialImpression !== "true" ||
            isBlank(clickStreamData.initialImpressionTag)) {
            return;
        }
        var pageProperties = isDefinedAndNotNull(clickStreamData.pageProperties)
            ? ObjectUtils.stringToObject(clickStreamData.pageProperties
                + "|" + INITIAL_IMPRESSION_TRACKING_PAGE_PROPERTIES)
            : ObjectUtils.stringToObject(INITIAL_IMPRESSION_TRACKING_PAGE_PROPERTIES);
        ClickStream.postClickStreamWithTrackingArguments({
            servletName: clickStreamData.servletName || ClickStream.getServlet(),
            pageAction: clickStreamData.scannedElement.getAttribute(DATA_TRACKING_INITIAL_IMPRESSION_TAG),
            pageProperties: pageProperties,
            destinationID: clickStreamData.destinationID,
            itineraryID: clickStreamData.itineraryID,
            productAttribute: clickStreamData.productAttribute,
            attractionProductId: clickStreamData.attractionProductId
        });
    };
    ClickStream.prototype.trackInitialImpressions = function () {
        var elements = this.scanElementsForInitialImpression();
        ClickStream.trackVisibleElements(elements, this.trackElementInitialImpression.bind(this));
    };
    ClickStream.PAGE_ACTION_URL = "/orion/pageaction/trackEvent";
    ClickStream.PAGE_VIEW_REQUEST_ID = "PAGE_VIEW_REQUEST_ID";
    ClickStream.PARENT_PAGE_VIEW_REQUEST_ID = "PARENT_PAGE_VIEW_REQUEST_ID";
    ClickStream.REFERER_PAGE_REQUEST_ID = "REFERER_PAGE_REQUEST_ID";
    ClickStream.PREFERS_COLOR_SCHEME_DARK = "prefers-color-scheme: dark";
    ClickStream.ajaxService = new AjaxService();
    ClickStream.errorMaps = {
        "parsley-creditcard": "invalid",
        "parsley-custom-error-message": "error",
        "parsley-length": "tooBig",
        "parsley-pattern": "invalid",
        "parsley-required": "empty",
        "parsley-type": "invalid",
        "parsley-minlength": "tooSmall"
    };
    return ClickStream;
}());
export { ClickStream };
