import $ from "./jquery";
export var collectFormInputs = function ($form) {
    var inputArray = $form.serializeArray();
    return $.extend.apply($, [{}].concat(inputArray.map(function (input) {
        var _a;
        return (_a = {}, _a[input.name] = input.value, _a);
    })));
};
var ObjectUtils = (function () {
    function ObjectUtils() {
    }
    ObjectUtils.safeGet = function (obj, key) {
        return key.split(".").reduce(function (o, x) {
            return (typeof o === "undefined" || o === null) ? o : o[x];
        }, obj);
    };
    ObjectUtils.stringToObject = function (stringToObject, entrySeparator, keyValueSeparator) {
        if (entrySeparator === void 0) { entrySeparator = "|"; }
        if (keyValueSeparator === void 0) { keyValueSeparator = ":"; }
        var convertedObject = {};
        if (stringToObject) {
            var entryArr = stringToObject.split(entrySeparator);
            for (var _i = 0, entryArr_1 = entryArr; _i < entryArr_1.length; _i++) {
                var entry = entryArr_1[_i];
                var keyValue = entry.split(keyValueSeparator);
                convertedObject[keyValue[0]] = keyValue[1];
            }
        }
        return convertedObject;
    };
    return ObjectUtils;
}());
export { ObjectUtils };
